import Vue from 'vue'

export default {
    showMessage(message) {
        Vue.notify({
            group: 'success-template',
            type: '',
            title: 'Thông báo',
            text: message
        });
    },
    showInfo(message) {
        Vue.notify({
            group: 'info-template',
            type: '',
            title: 'Thông báo',
            text: message
        });
    },
    showWarning(message) {
        Vue.notify({
            group: 'warning-template',
            type: '',
            title: 'Thông báo',
            text: message
        });
    },
    showError(message) {
        Vue.notify({
            group: 'error-template',
            type: '',
            title: 'Thông báo',
            text: message
        });
    },
}