import httpClient  from '../api';
const API_CONTROLLER = 'auth/';
import { TokenService } from './storageService'

export default {
    login(data) {
        let url = API_CONTROLLER + 'login';
        return httpClient.post(url, data);
    },

    refreshToken(){
        let url = API_CONTROLLER + 'refresh-token';
        const data = { token: TokenService.getToken(), refreshToken: TokenService.getRefreshToken() };
        return httpClient.post(url, data);
    }
}