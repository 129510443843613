<template>
    <div id="main-wrapper">
        <Header/>
        <div class="main-content">
            <router-view></router-view>
        </div>
        <Footer/>
    </div>
</template>

<script>
    import Header from '@/components/Header.vue';
    import Footer from '@/components/Footer.vue';
    export default {
        name: "DefaultLayout",
        components:{
            Header,
            Footer
        }
    };
</script>