<template>
     <div class="login-main-wrapper">
		<div class="login-main">
			<div class="login-img">
				<img alt="iqsoft" src="../../../assets/images/logo.png" >
			</div>
			<div class="login-form">
				<h3>Đăng nhập</h3>
				<form name="form" class="form" novalidate>
					<p>Please enter your name and password to log in.</p>

                    <md-field :class="{'md-invalid': submitted && $v.account.userName.$error }">
                        <md-icon>person</md-icon>
                        <label for="user-name">Tên đăng nhập</label>
                        <md-input name="user-name" v-model="account.userName" ></md-input>
                        <span class="md-error" v-if="submitted && !$v.account.userName.required">Vui lòng nhập tên đăng nhập</span>
                    </md-field>

                    <md-field :class="{'md-invalid': submitted && $v.account.password.$error }">
                        <md-icon>lock</md-icon>
                        <label for="password">Mật khẩu</label>
                        <md-input name="password" v-model="account.password" type="password"></md-input>
                        <span class="md-error" v-if="submitted && !$v.account.password.required">Vui lòng nhập  mật khẩu</span>
                    </md-field>

                    <md-checkbox id="remember" v-model="remember">Ghi nhớ tài khoản đăng nhập</md-checkbox>
                   
                    <div class="form-group login-btn">
                        <button v-on:click="login()" type="button" class="button" :class="{ 'button--loading': loadding }">
                            <span class="button__text">Đăng nhập <i class="fas fa-arrow-circle-right"></i></span>
                        </button>
					</div>
				</form>
				<div class="copyright">
					2024 © Copyright by
					<a target="_blank" href="https://ppl.com.vn/"><b>PPL Transport</b> </a>.
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import authService from '../../../api/authService';
import { required } from 'vuelidate/lib/validators';
import messageBox from '../../../utils/messageBox';
import { LoginInfoService } from '../../../api/storageService';
import $ from 'jquery';
import { mapActions } from 'vuex';

export default {
    data() {
        return {
            submitted: false,
            loadding: false,
            account: { userName: '', password: '' },
            remember: false
        }
    },
    created(){
        let username = LoginInfoService.getUser();
        if(username != null && username != ''){
            this.account.userName = username;
            this.account.password = LoginInfoService.getPassword();
            this.remember = true;
        }
        else{
            this.remember = false;
        }
    },
    mounted() {
        const that = this;
        $(document).ready(function(){
            window.addEventListener('keydown', function(e) {
                that.handleKeydown(e.keyCode);
            });
        });
    },
    methods: {
        ...mapActions('common', ['setLoggedIn', 'setAuth']),

        login() {
            this.submitted = true;
            this.loadding = false;
            this.$v.$touch();
            if (this.$v.$invalid) {
                return;
            }
            this.loadding = true;
            if(this.remember){
                LoginInfoService.saveUser(this.account.userName);
                LoginInfoService.savePassword(this.account.password);
            }
            else{
                LoginInfoService.removeUser();
                LoginInfoService.removePassword();
            }
            authService.login(this.account).then((response) => {
                if(response.statusCode == 200){
                    messageBox.showMessage('Đăng nhập thành công');
                    localStorage.removeItem('search-order')
                    this.setLoggedIn(true);
                    this.setAuth(response.data);
                    this.$router.push("/");
                }
                else{
                    messageBox.showWarning(response.data);
                }
            }).finally(() => { this.loadding = false; });
        },

        handleKeydown(keyCode) {
            if (keyCode == 13) {
                console.log(keyCode);
            }
        },
    },
    validations: {
        account: {
            userName: { required },
            password: { required },
        }
    },
}
</script>

<style scoped>
.login-main-wrapper {
    min-height: 100vh;
    background: url('../../../assets/images/bg.jpg');
    background-size: cover;
}

.login-main {
    background-color: #FFF;
    height: 450px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 0 2px 4px 0 rgb(212 212 212 / 50%);
    display: flex;
    flex-direction: row;
}

.login-img {
    background: url('../../../assets/images/login-form-left.png');
    width: 310px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding-top: 40px;
    background-size: cover;
}

.login-img img{
    width: 150px;
}

.login-form {
    width: 450px;
    padding: 40px 30px 5px 40px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}

.login-form h3{
    text-transform: uppercase;
    font-weight: 400;
    display: block;
    text-align: center;
}

.login-form .form{
    padding: 10px 0;
}

.login-form .form .form-group{
    margin-bottom: 1.5rem;
    width: 100%;
}

.login-form .form p{
    padding-bottom: 20px;
    color: #353535;
}

.login-form .copyright {
    border-top: 1px solid #eee;
    padding: 20px 0;
    text-align: center;
    color:#879aa9;
    font-size: 12px;
}

.login-form .copyright a {
    color: var(--color-primary);
    font-weight: 500;
}

.login-btn {
    text-align: center;
}

.login-form .md-checkbox{
    padding-left: 35px;
}

.login-form .md-field{
    margin: 4px 0 15px;
}

.login-form .md-error{
    padding-left: 35px;
}

@media (max-width: 920px){
    .login-main-wrapper {
        min-height: 100vh;
        background: rgba(249, 249, 249, 0.9);
    }
    .login-main {
       width: 100%;
       box-shadow: none;
    }
    .login-img {
        display: none;
    }
    .login-form {
        width: 100%;
    }
}
</style>