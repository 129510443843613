<template>
    <div class="page-content">
        <div class="grid">
            <div class="row">
                <div class="col l-2 m-2 c-12">
                    <md-card class="color-pink">
                        <md-card-header>
                            <md-card-header-text>
                                <div class="md-title">{{formatNumber(data.order)}}</div>
                                <div class="md-subhead">Đơn hàng</div>
                            </md-card-header-text>
                        </md-card-header>
                    </md-card>
                </div>
                <div class="col l-2 m-2 c-12">
                    <md-card class="color-blue">
                        <md-card-header>
                            <md-card-header-text>
                                <div class="md-title">{{ formatNumber(data.revenue) }}</div>
                                <div class="md-subhead">Phải thu khách hàng</div>
                            </md-card-header-text>
                        </md-card-header>
                    </md-card>
                </div>
                <div class="col l-2 m-2 c-12">
                    <md-card class="color-green">
                        <md-card-header>
                            <md-card-header-text>
                                <div class="md-title">{{ formatNumber(data.moneyReceived) }}</div>
                                <div class="md-subhead">Đã thu khách hàng</div>
                            </md-card-header-text>
                        </md-card-header>
                    </md-card>
                </div>
                <div class="col l-2 m-2 c-12">
                    <md-card class="color-yellow">
                        <md-card-header>
                            <md-card-header-text>
                                <div class="md-title">{{ formatNumber(data.moneyRemain) }}</div>
                                <div class="md-subhead">Còn lại</div>
                            </md-card-header-text>
                        </md-card-header>
                    </md-card>
                </div>
                <div class="col l-2 m-2 c-12">
                    <md-card class="color-purple">
                        <md-card-header>
                            <md-card-header-text>
                                <div class="md-title">{{ formatNumber(data.goodsRoute) }}</div>
                                <div class="md-subhead">Hành trình</div>
                            </md-card-header-text>
                        </md-card-header>
                    </md-card>
                </div>
                <div class="col l-2 m-2 c-12">
                    <md-card class="color-mix">
                        <md-card-header>
                            <md-card-header-text>
                                <div class="md-title">{{ formatNumber(data.jobRoute) }}</div>
                                <div class="md-subhead">Hành trình đã điều xe</div>
                            </md-card-header-text>
                        </md-card-header>
                    </md-card>
                </div>
            </div>  
        </div>
    </div>
</template>
<script>
    import reportDashboardService from '../../api/reportDashboardService';
    import messageBox from '../../utils/messageBox';
    import { mapActions } from 'vuex';

    export default {
        metaInfo: {
            title: 'Dashboard'
        },
        data() {
            return {
                loadding: false,
                data: { order: 0, revenue:0, moneyReceived: 0, moneyRemain: 0, goodsRoute: 0, jobRoute: 0, routeRemain: 0 }
            }
        },
        created(){
            this.getDashboard();
        },
        methods: {
            ...mapActions('common', ['setLoading']),

            getDashboard(){
                this.loadding = true;
                reportDashboardService.getDashboard().then((response) => {
                    if(response.statusCode == 200){
                        this.data = response.data;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.loadding = false; });
            },
        }
    }
</script>

<style scoped>
    .md-card .md-subhead {
        opacity: 1 !important;
    }

    .md-card.md-theme-default.color-pink{
        background: linear-gradient(to right, #ffbf96, #fe7096) !important;
    }

    .md-card.md-theme-default.color-blue{
        background: linear-gradient(to right, #90caf9, #047edf 99%) !important;
    }

    .md-card.md-theme-default.color-green{
        background: linear-gradient(to right, #84d9d2, #07cdae) !important;
    }

    .md-card.md-theme-default.color-yellow{
        background: linear-gradient(to right, #f6e384, #ffd500) !important;
    }

    .md-card.md-theme-default.color-purple {
        background: linear-gradient(to right, #da8cff, #9a55ff) !important;
    }

    .md-card.md-theme-default.color-mix {
        background: linear-gradient(to right, rgba(54, 215, 232, 1), rgba(177, 148, 250, 1)) !important;
    }
</style>