import Vue from 'vue'
import App from './App.vue'
import router from "./router";
import store from './store';
import mixin from './mixins/helpers';

Vue.config.productionTip = false

import Vuelidate from 'vuelidate'
Vue.use(Vuelidate)

import Notifications from 'vue-notification'
Vue.use(Notifications)

import vmodal from 'vue-js-modal'
Vue.use(vmodal, { dialog: true })

import VueMask from 'v-mask'
Vue.use(VueMask);

import VueMeta from 'vue-meta'
Vue.use(VueMeta)

import VueDatepickerUi from 'vue-datepicker-ui'
import 'vue-datepicker-ui/lib/vuedatepickerui.css';
Vue.component('Datepicker', VueDatepickerUi)

import VueMaterial from 'vue-material';
Vue.use(VueMaterial);

Vue.use(require('vue-shortkey'));

import ProgressBar from 'vuejs-progress-bar'
Vue.use(ProgressBar);

import number from '@coders-tm/vue-number-format'
Vue.use(number, {   
      decimal: '.',
      separator: ',',
      prefix: 'VNĐ ',
      precision: 2,
      masked: false,
      suffix: ' VNĐ',
  }
)

const paging = {
  firstPage: "Trang đầu",
  prePage: "Trang trước",
  nextPage: "Trang sau",
  lastPage: "Trang cuối",
  page: "Trang",
  rowOfPage: "dòng/trang",
  view: "xem",
  rows: "bản ghi",
  of: "/"
}

import VueHtmlToPaper from 'vue-html-to-paper';
const options = {
  name: '_blank',
  specs: [
    'fullscreen=yes',
    'titlebar=yes',
    'scrollbars=yes'
  ],
  timeout: 1000, // default timeout before the print window appears
  autoClose: true, // if false, the window will not close after printing
  windowTitle: window.document.title, // override the window title
}
Vue.use(VueHtmlToPaper, options);

Vue.prototype.paging = paging

new Vue({
  router,
  store,
  mixin,
  render: h => h(App),
  el: "#app"
});

