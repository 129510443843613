import * as types from "./constants";
import { TokenService } from './../../api/storageService';

export default {
	[types.SET_LOADING](state, data) {
		state.loading = data;
	},

	auth_request(state) {
		state.status = 'loading'
		state.token = ''
		state.refreshToken = ''
		state.user = ''
	},

	auth_success(state, user) {
		state.status = 'success'
		state.token = user.token
		state.refreshToken = user.refreshToken
		state.user = user
		TokenService.saveToken(user.token);
		TokenService.saveRefreshToken(user.refreshToken);
	},

	auth_error(state) {
		state.status = 'error'
		state.token = ''
		state.refreshToken = ''
		state.user = ''
	},

	logged_in(state, loggedIn) {
		state.loggedIn = loggedIn
	},

	logout(state) {
		state.status = ''
		state.token = ''
		state.refreshToken = ''
		state.user = ''
		state.loggedIn = false
		TokenService.removeToken();
		TokenService.removeRefreshToken();
	},
};