import Vue from "vue";
import Router from "vue-router";
import DefaultLayout from "./layouts/Default.vue";
import EmptyLayout from "./layouts/Empty.vue";
import Login from "./views/system/auth/Login.vue";
import Home from "./views/home/Index.vue";
import { TokenService } from './../src/api/storageService';

Vue.use(Router);

let router = new Router({
    mode: "history",
    routes: [
        {
            path: "/",
            component: DefaultLayout,
            meta: {
                requiresAuth: true
            },
            children: [
                {
                    path: '',
                    name: 'HomePage',
                    component: Home
                },
                {
                    path: "/dashboard",
                    name: 'DashboardPage',
                    component: Home
                },
                {
                    path: "/admin",
                    name: 'AdminList',
                    component: () => import ("./views/system/admin/List.vue"),
                },
                {
                    path: "/admin/crud/:id?",
                    name: 'AdminCrud',
                    component: () => import ("./views/system/admin/Crud.vue"),
                },
                {
                    path: "/role",
                    name: 'RoleList',
                    component: () => import ("./views/system/role/List.vue"),
                },
                {
                    path: "/role/crud/:id?",
                    name: 'RoleCrud',
                    component: () => import ("./views/system/role/Crud.vue"),
                },
                {
                    path: "/module",
                    name: 'ModuleList',
                    component: () => import ("./views/system/module/List.vue"),
                },
                {
                    path: "/module/crud/:id?",
                    name: 'ModuleCrud',
                    component: () => import ("./views/system/module/Crud.vue"),
                },
                {
                    path: "/permission",
                    name: 'permission',
                    component: () => import ("./views/system/permission/Index.vue"),
                },
                {
                    path: "/permission-dept",
                    name: 'permissionDept',
                    component: () => import ("./views/system/permission/Dept.vue"),
                },
                {
                    path: "/setting",
                    name: 'Setting',
                    component: () => import ("./views/setting/Crud.vue"),
                },
                {
                    path: "/user/:page?",
                    name: 'user',
                    component: () => import ("./views/user/Index.vue"),
                },
                {
                    path: "/log",
                    name: 'logList',
                    component: () => import ("./views/system/log/List.vue"),
                },
                {
                    path: "/log/crud/:id?",
                    name: 'logCrud',
                    component: () => import ("./views/system/log/Crud.vue"),
                },
                {
                    path: "/location",
                    name: 'locationList',
                    component: () => import ("./views/master-data/location/List.vue"),
                },
                {
                    path: "/location/crud/:id?",
                    name: 'locationCrud',
                    component: () => import ("./views/master-data/location/Crud.vue"),
                },
                {
                    path: "/currency",
                    name: 'currencyList',
                    component: () => import ("./views/master-data/currency/List.vue"),
                },
                {
                    path: "/currency/crud/:id?",
                    name: 'currencyCrud',
                    component: () => import ("./views/master-data/currency/Crud.vue"),
                },
                {
                    path: "/unit",
                    name: 'unitList',
                    component: () => import ("./views/master-data/unit/List.vue"),
                },
                {
                    path: "/unit/crud/:id?",
                    name: 'unitCrud',
                    component: () => import ("./views/master-data/unit/Crud.vue"),
                },
                {
                    path: "/branch",
                    name: 'branchList',
                    component: () => import ("./views/master-data/branch/List.vue"),
                },
                {
                    path: "/branch/crud/:id?",
                    name: 'branchCrud',
                    component: () => import ("./views/master-data/branch/Crud.vue"),
                },
                {
                    path: "/department",
                    name: 'departmentList',
                    component: () => import ("./views/master-data/department/List.vue"),
                },
                {
                    path: "/department/crud/:id?",
                    name: 'departmentCrud',
                    component: () => import ("./views/master-data/department/Crud.vue"),
                },
                {
                    path: "/store",
                    name: 'storeList',
                    component: () => import ("./views/master-data/store/List.vue"),
                },
                {
                    path: "/store/crud/:id?",
                    name: 'storeCrud',
                    component: () => import ("./views/master-data/store/Crud.vue"),
                },
                {
                    path: "/type",
                    name: 'typeList',
                    component: () => import ("./views/master-data/type/List.vue"),
                },
                {
                    path: "/type/crud/:id?",
                    name: 'typeCrud',
                    component: () => import ("./views/master-data/type/Crud.vue"),
                },
                {
                    path: "/product",
                    name: 'productList',
                    component: () => import ("./views/master-data/product/List.vue"),
                },
                {
                    path: "/product/crud/:id?",
                    name: 'productCrud',
                    component: () => import ("./views/master-data/product/Crud.vue"),
                },
                {
                    path: "/staff",
                    name: 'staffList',
                    component: () => import ("./views/master-data/staff/List.vue"),
                },
                {
                    path: "/staff/crud/:id?",
                    name: 'staffCrud',
                    component: () => import ("./views/master-data/staff/Crud.vue"),
                },
                {
                    path: "/country",
                    name: 'countryList',
                    component: () => import ("./views/master-data/country/List.vue"),
                },
                {
                    path: "/country/crud/:id?",
                    name: 'countryCrud',
                    component: () => import ("./views/master-data/country/Crud.vue"),
                },
                {
                    path: "/brand",
                    name: 'brandList',
                    component: () => import ("./views/master-data/brand/List.vue"),
                },
                {
                    path: "/brand/crud/:id?",
                    name: 'brandCrud',
                    component: () => import ("./views/master-data/brand/Crud.vue"),
                },
                {
                    path: "/store-export",
                    name: 'store-exportList',
                    component: () => import ("./views/store-manage/store-export/List.vue"),
                },
                {
                    path: "/store-export/crud/:id?",
                    name: 'store-exportCrud',
                    component: () => import ("./views/store-manage/store-export/Crud.vue"),
                },
                {
                    path: "/store-export/approve-list",
                    name: 'store-export-approve-list',
                    component: () => import ("./views/store-manage/store-export/ApproveList.vue"),
                },
                {
                    path: "/store-export/approve/:id",
                    name: 'store-export-approve-crud',
                    component: () => import ("./views/store-manage/store-export/ApproveEdit.vue"),
                },
                {
                    path: "/store-export/view/:id",
                    name: 'store-export-view',
                    component: () => import ("./views/store-manage/store-export/View.vue"),
                },
                {
                    path: "/store-export/view-attach/:id",
                    name: 'store-export-view-attach',
                    component: () => import ("./views/store-manage/store-export/ViewAttach.vue"),
                },
                {
                    path: "/store-receipt",
                    name: 'store-receiptList',
                    component: () => import ("./views/store-manage/store-receipt/List.vue"),
                },
                {
                    path: "/store-receipt/crud/:id?",
                    name: 'store-receiptCrud',
                    component: () => import ("./views/store-manage/store-receipt/Crud.vue"),
                },
                {
                    path: "/store-receipt/approve-list",
                    name: 'store-receipt-approve-list',
                    component: () => import ("./views/store-manage/store-receipt/ApproveList.vue"),
                },
                {
                    path: "/store-receipt/approve/:id",
                    name: 'store-receipt-approve-crud',
                    component: () => import ("./views/store-manage/store-receipt/ApproveEdit.vue"),
                },
                {
                    path: "/store-receipt/view/:id",
                    name: 'storeReceiptView',
                    component: () => import ("./views/store-manage/store-receipt/View.vue"),
                },
                {
                    path: "/store-receipt/view-attach/:id",
                    name: 'store-receipt-view-attach',
                    component: () => import ("./views/store-manage/store-receipt/ViewAttach.vue"),
                },
                {
                    path: "/vehicle",
                    name: 'vehicleList',
                    component: () => import ("./views/vehicle-manage/vehicle/List.vue"),
                },
                {
                    path: "/vehicle/crud/:id?",
                    name: 'vehicleCrud',
                    component: () => import ("./views/vehicle-manage/vehicle/Crud.vue"),
                },
                {
                    path: "/vehicle-group",
                    name: 'vehicleGroupList',
                    component: () => import ("./views/vehicle-manage/vehicle-group/List.vue"),
                },
                {
                    path: "/vehicle-group/crud/:id?",
                    name: 'vehicleGroupCrud',
                    component: () => import ("./views/vehicle-manage/vehicle-group/Crud.vue"),
                },
                {
                    path: "/forklift",
                    name: 'forkliftList',
                    component: () => import ("./views/vehicle-manage/forklift/List.vue"),
                },
                {
                    path: "/forklift/crud/:id?",
                    name: 'forkliftCrud',
                    component: () => import ("./views/vehicle-manage/forklift/Crud.vue"),
                },
                {
                    path: "/forklift-group",
                    name: 'forkliftGroupList',
                    component: () => import ("./views/vehicle-manage/forklift-group/List.vue"),
                },
                {
                    path: "/forklift-group/crud/:id?",
                    name: 'forkliftGroupCrud',
                    component: () => import ("./views/vehicle-manage/forklift-group/Crud.vue"),
                },
                {
                    path: "/bank",
                    name: 'bankList',
                    component: () => import ("./views/company/bank/List.vue"),
                },
                {
                    path: "/bank/crud/:id?",
                    name: 'bankCrud',
                    component: () => import ("./views/company/bank/Crud.vue"),
                },
                {
                    path: "/company-customs",
                    name: 'companyCustomsList',
                    component: () => import ("./views/company/customs/List.vue"),
                },
                {
                    path: "/company-customs/crud/:id?",
                    name: 'companyCustomsCrud',
                    component: () => import ("./views/company/customs/Crud.vue"),
                },
                {
                    path: "/transport-company",
                    name: 'transportCompanyList',
                    component: () => import ("./views/company/transport/List.vue"),
                },
                {
                    path: "/transport-company/crud/:id?",
                    name: 'transportCompanyCrud',
                    component: () => import ("./views/company/transport/Crud.vue"),
                },
                {
                    path: "/company-fuel",
                    name: 'company-fuel-list',
                    component: () => import ("./views/company/fuel/List.vue"),
                },
                {
                    path: "/company-fuel/crud/:id?",
                    name: 'company-fuel-crud',
                    component: () => import ("./views/company/fuel/Crud.vue"),
                },
                {
                    path: "/insurance",
                    name: 'insuranceList',
                    component: () => import ("./views/company/insurance/List.vue"),
                },
                {
                    path: "/insurance/crud/:id?",
                    name: 'insuranceCrud',
                    component: () => import ("./views/company/insurance/Crud.vue"),
                },
                {
                    path: "/supplier",
                    name: 'supplierList',
                    component: () => import ("./views/company/supplier/List.vue"),
                },
                {
                    path: "/supplier/crud/:id?",
                    name: 'supplierCrud',
                    component: () => import ("./views/company/supplier/Crud.vue"),
                },
                {
                    path: "/company-repair",
                    name: 'companyRepairList',
                    component: () => import ("./views/company/repair/List.vue"),
                },
                {
                    path: "/company-repair/crud/:id?",
                    name: 'companyRepairCrud',
                    component: () => import ("./views/company/repair/Crud.vue"),
                },
                {
                    path: "/company-invoice",
                    name: 'company-invoice-list',
                    component: () => import ("./views/company/invoice/List.vue"),
                },
                {
                    path: "/company-invoice/crud/:id?",
                    name: 'company-invoice-crud',
                    component: () => import ("./views/company/invoice/Crud.vue"),
                },
                {
                    path: "/contractor",
                    name: 'contractorList',
                    component: () => import ("./views/company/contractor/List.vue"),
                },
                {
                    path: "/contractor/crud/:id?",
                    name: 'contractorCrud',
                    component: () => import ("./views/company/contractor/Crud.vue"),
                },
                {
                    path: "/customer",
                    name: 'customerList',
                    component: () => import ("./views/company/customer/List.vue"),
                },
                {
                    path: "/customer/crud/:id?",
                    name: 'customerCrud',
                    component: () => import ("./views/company/customer/Crud.vue"),
                },
                {
                    path: "/shipping-lines",
                    name: 'shippingList',
                    component: () => import ("./views/company/shipping/List.vue"),
                },
                {
                    path: "/shipping-lines/crud/:id?",
                    name: 'shippingCrud',
                    component: () => import ("./views/company/shipping/Crud.vue"),
                },
                {
                    path: "/owner",
                    name: 'owner',
                    component: () => import ("./views/company/owner/Crud.vue"),
                },
                {
                    path: "/company-port",
                    name: 'portCompanyList',
                    component: () => import ("./views/company/port/List.vue"),
                },
                {
                    path: "/company-port/crud/:id?",
                    name: 'portCompanyCrud',
                    component: () => import ("./views/company/port/Crud.vue"),
                },
                {
                    path: "/project",
                    name: 'projectList',
                    component: () => import ("./views/project-manage/project/List.vue"),
                },
                {
                    path: "/project/crud/:id?",
                    name: 'projectCrud',
                    component: () => import ("./views/project-manage/project/Crud.vue"),
                },
                {
                    path: "/contract",
                    name: 'contractList',
                    component: () => import ("./views/project-manage/contract/List.vue"),
                },
                {
                    path: "/contract/crud/:id?",
                    name: 'contractCrud',
                    component: () => import ("./views/project-manage/contract/Crud.vue"),
                },
                {
                    path: "/warehouse",
                    name: 'warehouseList',
                    component: () => import ("./views/warehouse/List.vue"),
                },
                {
                    path: "/warehouse/crud/:id?",
                    name: 'warehouseCrud',
                    component: () => import ("./views/warehouse/Crud.vue"),
                },
                {
                    path: "/area",
                    name: 'areaList',
                    component: () => import ("./views/area/List.vue"),
                },
                {
                    path: "/area/crud/:id?",
                    name: 'areaCrud',
                    component: () => import ("./views/area/Crud.vue"),
                },
                {
                    path: "/repair-estimate",
                    name: 'repairEstimateList',
                    component: () => import ("./views/repair-manage/estimate/List.vue"),
                },
                {
                    path: "/repair-estimate/crud/:id?",
                    name: 'repairEstimateCrud',
                    component: () => import ("./views/repair-manage/estimate/Crud.vue"),
                },
                {
                    path: "/repair-estimate/view/:id?",
                    name: 'repairEstimateView',
                    component: () => import ("./views/repair-manage/estimate/View.vue"),
                },
                {
                    path: "/repair-estimate/view-attach/:id",
                    name: 'repairEstimateViewAttach',
                    component: () => import ("./views/repair-manage/estimate/ViewAttach.vue"),
                },
                {
                    path: "/request-repair",
                    name: 'requestRepairList',
                    component: () => import ("./views/repair-manage/request/List.vue"),
                },
                {
                    path: "/request-repair/crud/:id?",
                    name: 'requestrepairCrud',
                    component: () => import ("./views/repair-manage/request/Crud.vue"),
                },
                {
                    path: "/request-repair/view/:id",
                    name: 'requestrepairView',
                    component: () => import ("./views/repair-manage/request/View.vue"),
                },
                {
                    path: "/request-repair/view-attach/:id",
                    name: 'requestrepairViewAttach',
                    component: () => import ("./views/repair-manage/request/ViewAttach.vue"),
                },
                {
                    path: "/approve-setting",
                    name: 'approveSettingList',
                    component: () => import ("./views/master-data/approve-setting/List.vue"),
                },
                {
                    path: "/approve-setting/crud/:id?",
                    name: 'approveSettingCrud',
                    component: () => import ("./views/master-data/approve-setting/Crud.vue"),
                },
                {
                    path: "/goods",
                    name: 'goodsList',
                    component: () => import ("./views/transport-manage/goods/List.vue"),
                },
                {
                    path: "/goods/crud/:id?",
                    name: 'goodsCrud',
                    component: () => import ("./views/transport-manage/goods/Crud.vue"),
                },
                {
                    path: "/packing-list",
                    name: 'pklList',
                    component: () => import ("./views/transport-manage/packing-list/List.vue"),
                },
                {
                    path: "/packing-list/crud/:id?",
                    name: 'pklCrud',
                    component: () => import ("./views/transport-manage/packing-list/Crud.vue"),
                },
                {
                    path: "/transport-plan",
                    name: 'transportPlanList',
                    component: () => import ("./views/transport-manage/transport-plan/List.vue"),
                },
                {
                    path: "/transport-plan/crud/:id?",
                    name: 'transportPlanCrud',
                    component: () => import ("./views/transport-manage/transport-plan/Crud.vue"),
                },
                {
                    path: "/transport-type",
                    name: 'transportTypeList',
                    component: () => import ("./views/transport-manage/transport-type/List.vue"),
                },
                {
                    path: "/transport-type/crud/:id?",
                    name: 'transportTypeCrud',
                    component: () => import ("./views/transport-manage/transport-type/Crud.vue"),
                },
                {
                    path: "/type-transport",
                    name: 'TypetransportList',
                    component: () => import ("./views/transport-manage/type-transport/List.vue"),
                },
                {
                    path: "/type-transport/crud/:id?",
                    name: 'TypetransportCrud',
                    component: () => import ("./views/transport-manage/type-transport/Crud.vue"),
                },
                {
                    path: "/container-type",
                    name: 'containerTypeList',
                    component: () => import ("./views/transport-manage/container-type/List.vue"),
                },
                {
                    path: "/container-type/crud/:id?",
                    name: 'containerTypeCrud',
                    component: () => import ("./views/transport-manage/container-type/Crud.vue"),
                },
                {
                    path: "/transport-unit",
                    name: 'transportUnitList',
                    component: () => import ("./views/transport-manage/transport-unit/List.vue"),
                },
                {
                    path: "/order-plan",
                    name: 'orderPlanList',
                    component: () => import ("./views/transport-manage/order-plan/List.vue"),
                },
                {
                    path: "/order-plan/crud/:id?",
                    name: 'orderPlanCrud',
                    component: () => import ("./views/transport-manage/order-plan/Crud.vue"),
                },
                {
                    path: "/request-type",
                    name: 'requestTypeList',
                    component: () => import ("./views/transport-manage/request-type/List.vue"),
                },
                {
                    path: "/request-type/crud/:id?",
                    name: 'requestTypeCrud',
                    component: () => import ("./views/transport-manage/request-type/Crud.vue"),
                },
                {
                    path: "/transport-unit/crud/:id?",
                    name: 'transportUnitCrud',
                    component: () => import ("./views/transport-manage/transport-unit/Crud.vue"),
                },
                {
                    path: "/order",
                    name: 'orderList',
                    component: () => import ("./views/order-manage/order/List.vue"),
                },
                {
                    path: "/order/crud/:id?",
                    name: 'orderCrud',
                    component: () => import ("./views/order-manage/order/Crud.vue"),
                },
                {
                    path: "/driver",
                    name: 'driverList',
                    component: () => import ("./views/master-data/driver/List.vue"),
                },
                {
                    path: "/driver/crud/:id?",
                    name: 'driverCrud',
                    component: () => import ("./views/master-data/driver/Crud.vue"),
                },
                {
                    path: "/fee",
                    name: 'feeList',
                    component: () => import ("./views/transport-manage/fee/List.vue"),
                },
                {
                    path: "/fee/crud/:id?",
                    name: 'feeCrud',
                    component: () => import ("./views/transport-manage/fee/Crud.vue"),
                },
                {
                    path: "/freight",
                    name: 'freightList',
                    component: () => import ("./views/transport-manage/freight/List.vue"),
                },
                {
                    path: "/freight/crud/:id?",
                    name: 'freightCrud',
                    component: () => import ("./views/transport-manage/freight/Crud.vue"),
                },
                {
                    path: "/category-customer",
                    name: 'categoryCustomerList',
                    component: () => import ("./views/master-data/category-customer/List.vue"),
                },
                {
                    path: "/category-customer/crud/:id?",
                    name: 'categoryCustomerCrud',
                    component: () => import ("./views/master-data/category-customer/Crud.vue"),
                },
                {
                    path: "/category-fee",
                    name: 'categoryFeeList',
                    component: () => import ("./views/master-data/category-fee/List.vue"),
                },
                {
                    path: "/category-fee/crud/:id?",
                    name: 'categoryFeeCrud',
                    component: () => import ("./views/master-data/category-fee/Crud.vue"),
                },
                {
                    path: "/report-receipt",
                    name: 'reportReceipt',
                    component: () => import ("./views/store-manage/report-receipt/ReportReceipt.vue"),
                },
                {
                    path: "/report-export",
                    name: 'reportExport',
                    component: () => import ("./views/store-manage/report-export/ReportExport.vue"),
                },
                {
                    path: "/report-inventory",
                    name: 'reportInventory',
                    component: () => import ("./views/store-manage/report-inventory/ReportInventory.vue"),
                },
                {
                    path: "/report-job",
                    name: 'reportJob',
                    component: () => import ("./views/report/report-job/ReportJob.vue"),
                },
                {
                    path: "/report-order",
                    name: 'reportOrder',
                    component: () => import ("./views/report/report-order/ReportOrder.vue"),
                },
                {
                    path: "/report-customer",
                    name: 'reportCustomer',
                    component: () => import ("./views/report/report-customer/ReportCustomer.vue"),
                },
                {
                    path: "/report-driver",
                    name: 'reportDriver',
                    component: () => import ("./views/report/report-driver/ReportDriver.vue"),
                },
                {
                    path: "/report-contract",
                    name: 'reportContract',
                    component: () => import ("./views/report/report-contract/ReportContract.vue"),
                },
                {
                    path: "/report-project",
                    name: 'reportProject',
                    component: () => import ("./views/report/report-project/ReportProject.vue"),
                },
                {
                    path: "/report-repair-summary",
                    name: 'reportRepairSummaryProject',
                    component: () => import ("./views/repair-manage/report-summary/ReportSummary.vue"),
                },
                {
                    path: "/repair-invoice",
                    name: 'invoiceRepairList',
                    component: () => import ("./views/repair-manage/invoice/List.vue"),
                },
                {
                    path: "/repair-invoice/crud/:id?",
                    name: 'invoiceRepairCrud',
                    component: () => import ("./views/repair-manage/invoice/Crud.vue"),
                },
                {
                    path: "/repair-invoice/view/:id",
                    name: 'invoiceRepairView',
                    component: () => import ("./views/repair-manage/invoice/View.vue"),
                },
                {
                    path: "/repair-invoice/approve-list",
                    name: 'invoiceRepairApproveList',
                    component: () => import ("./views/repair-manage/invoice/ApproveList.vue"),
                },
                {
                    path: "/repair-invoice/approve/:id",
                    name: 'invoiceRepairApproveCrud',
                    component: () => import ("./views/repair-manage/invoice/ApproveEdit.vue"),
                },
                {
                    path: "/request-repair/approve-list",
                    name: 'approveRequestRepair',
                    component: () => import ("./views/repair-manage/request/ApproveList.vue"),
                },
                {
                    path: "/request-repair/approve/:id",
                    name: 'submitApproveRequestRepair',
                    component: () => import ("./views/repair-manage/request/ApproveEdit.vue"),
                },
                {
                    path: "/repair-estimate/approve-list",
                    name: 'RepairEstimateApproveList',
                    component: () => import ("./views/repair-manage/estimate/ApproveList.vue"),
                },
                {
                    path: "/repair-estimate/approve/:id",
                    name: 'RepairEstimateApprove',
                    component: () => import ("./views/repair-manage/estimate/ApproveEdit.vue"),
                },
                {
                    path: "/request-estimate/view-attach/:id",
                    name: 'requestEstimateViewAttach',
                    component: () => import ("./views/repair-manage/estimate/ViewAttach.vue"),
                },
                {
                    path: "/repair-advance-payment",
                    name: 'repairAdvancePaymentList',
                    component: () => import ("./views/repair-manage/advance-payment/List.vue"),
                },
                {
                    path: "/repair-advance-payment/crud/:id?",
                    name: 'repairAdvancePaymentCrud',
                    component: () => import ("./views/repair-manage/advance-payment/Crud.vue"),
                },
                {
                    path: "/repair-advance-payment/view/:id",
                    name: 'repairAdvancePaymentView',
                    component: () => import ("./views/repair-manage/advance-payment/View.vue"),
                },
                {
                    path: "/repair-advance-payment/approve-list",
                    name: 'repairAdvancePaymentApproveList',
                    component: () => import ("./views/repair-manage/advance-payment/ApproveList.vue"),
                },
                {
                    path: "/repair-advance-payment/approve/:id",
                    name: 'repairAdvancePaymentApproveEdit',
                    component: () => import ("./views/repair-manage/advance-payment/ApproveEdit.vue"),
                },
                {
                    path: "/notify",
                    name: 'notifyList',
                    component: () => import ("./views/notify/List.vue"),
                },
                {
                    path: "/job-estimate",
                    name: 'jobEstimateList',
                    component: () => import ("./views/job-manage/job-estimate/List.vue"),
                },
                {
                    path: "/job-estimate/crud/:id?",
                    name: 'jobEstimateCrud',
                    component: () => import ("./views/job-manage/job-estimate/Crud.vue"),
                },
                {
                    path: "/job-estimate/view/:id",
                    name: 'jobEstimateView',
                    component: () => import ("./views/job-manage/job-estimate/View.vue"),
                },
                {
                    path: "/job-estimate/approve-list",
                    name: 'jobEstimateApproveList',
                    component: () => import ("./views/job-manage/job-estimate/ApproveList.vue"),
                },
                {
                    path: "/job-estimate/approve/:id",
                    name: 'jobEstimateApprove',
                    component: () => import ("./views/job-manage/job-estimate/ApproveEdit.vue"),
                },
                {
                    path: "/job-estimate/request-edit/:id",
                    name: 'jobEstimateApproveEdit',
                    component: () => import ("./views/job-manage/job-estimate/RequestEdit.vue"),
                },
                {
                    path: "/job-estimate/view-history/:id",
                    name: 'jobEstimateHistory',
                    component: () => import ("./views/job-manage/job-estimate/History.vue"),
                },
                {
                    path: "/job-invoice",
                    name: 'jobInvoiceList',
                    component: () => import ("./views/job-manage/job-invoice/List.vue"),
                },
                {
                    path: "/job-invoice/view/:id",
                    name: 'jobInvoiceView',
                    component: () => import ("./views/job-manage/job-invoice/View.vue"),
                },
                {
                    path: "/job-invoice/approve-list",
                    name: 'jobInvoiceApproveList',
                    component: () => import ("./views/job-manage/job-invoice/ApproveList.vue"),
                },
                {
                    path: "/job-invoice/approve/:id",
                    name: 'jobInvoiceApprove',
                    component: () => import ("./views/job-manage/job-invoice/ApproveEdit.vue"),
                },
                {
                    path: "/revenue",
                    name: 'revenueList',
                    component: () => import ("./views/revenue/List.vue"),
                },
                {
                    path: "/revenue/crud/:id?",
                    name: 'revenueCrud',
                    component: () => import ("./views/revenue/Crud.vue"),
                },
                {
                    path: "/revenue/view/:id",
                    name: 'revenueView',
                    component: () => import ("./views/revenue/View.vue"),
                },
                {
                    path: "/goods-routes",
                    name: 'goodsRoutesList',
                    component: () => import ("./views/order-manage/GoodsRoute.vue"),
                },
                {
                    path: "/report-order-fee",
                    name: 'reportOrderFee',
                    component: () => import ("./views/report/report-order-fee/ReportOrder.vue"),
                },
                {
                    path: "/report-order-job-fee",
                    name: 'reportOrderJobFee',
                    component: () => import ("./views/report/report-order-job-fee/ReportOrderJob.vue"),
                },
                {
                    path: "/refund",
                    name: 'refundList',
                    component: () => import ("./views/debt-manager/refund/List.vue"),
                },
                {
                    path: "/debt-vendor",
                    name: 'debtVendorList',
                    component: () => import ("./views/debt-manager/debt-vendor/List.vue"),
                },
                {
                    path: "/debt-vendor/crud/:id?",
                    name: 'debtVendorCrud',
                    component: () => import ("./views/debt-manager/debt-vendor/Crud.vue"),
                },
                {
                    path: "/debt-customer",
                    name: 'debtCustomerList',
                    component: () => import ("./views/debt-manager/debt-customer/List.vue"),
                },
                {
                    path: "/debt-customer/crud/:id?",
                    name: 'debtCustomerCrud',
                    component: () => import ("./views/debt-manager/debt-customer/Crud.vue"),
                },
                {
                    path: "/payment-customer",
                    name: 'paymentCustomerList',
                    component: () => import ("./views/debt-manager/payment-customer/List.vue"),
                },
                {
                    path: "/payment-customer/crud/:id?",
                    name: 'paymentCustomerCrud',
                    component: () => import ("./views/debt-manager/payment-customer/Crud.vue"),
                },
                {
                    path: "/payment-vendor",
                    name: 'paymentOutsourceList',
                    component: () => import ("./views/debt-manager/payment-vendor/List.vue"),
                },
                {
                    path: "/payment-vendor/crud/:id?",
                    name: 'paymentOutsourceCrud',
                    component: () => import ("./views/debt-manager/payment-vendor/Crud.vue"),
                },
                {
                    path: "/email-template",
                    name: 'emailTemplateList',
                    component: () => import ("./views/master-data/email-template/List.vue"),
                },
                {
                    path: "/email-template/crud/:id?",
                    name: 'emailTemplateCrud',
                    component: () => import ("./views/master-data/email-template/Crud.vue"),
                },
                {
                    path: "/request-registration",
                    name: 'requestRegistrationList',
                    component: () => import ("./views/registration-manage/request/List.vue"),
                },
                {
                    path: "/request-registration/crud/:id?",
                    name: 'requestRegistrationCrud',
                    component: () => import ("./views/registration-manage/request/Crud.vue"),
                },
                {
                    path: "/request-registration/view/:id?",
                    name: 'requestRegistrationView',
                    component: () => import ("./views/registration-manage/request/View.vue"),
                },
                {
                    path: "/request-registration/approve-list",
                    name: 'requestRegistrationApproveList',
                    component: () => import ("./views/registration-manage/request/ApproveList.vue"),
                },
                {
                    path: "/request-registration/approve/:id",
                    name: 'requestRegistrationApproveEdit',
                    component: () => import ("./views/registration-manage/request/ApproveEdit.vue"),
                },
                {
                    path: "/registration-estimate",
                    name: 'registrationEstimateList',
                    component: () => import ("./views/registration-manage/estimate/List.vue"),
                },
                {
                    path: "/registration-estimate/crud/:id?",
                    name: 'registrationEstimateCrud',
                    component: () => import ("./views/registration-manage/estimate/Crud.vue"),
                },
                {
                    path: "/registration-estimate/view/:id?",
                    name: 'registrationEstimateView',
                    component: () => import ("./views/registration-manage/estimate/View.vue"),
                },
                {
                    path: "/registration-estimate/view-attach/:id",
                    name: 'registrationEstimateViewAttach',
                    component: () => import ("./views/registration-manage/estimate/ViewAttach.vue"),
                },
                {
                    path: "/registration-estimate/approve-list",
                    name: 'registrationEstimateApproveList',
                    component: () => import ("./views/registration-manage/estimate/ApproveList.vue"),
                },
                {
                    path: "/registration-estimate/approve/:id",
                    name: 'registrationEstimateApprove',
                    component: () => import ("./views/registration-manage/estimate/ApproveEdit.vue"),
                },
                {
                    path: "/registration-advance-payment",
                    name: 'registrationAdvancePaymentList',
                    component: () => import ("./views/registration-manage/advance-payment/List.vue"),
                },
                {
                    path: "/registration-advance-payment/crud/:id?",
                    name: 'registrationAdvancePaymentCrud',
                    component: () => import ("./views/registration-manage/advance-payment/Crud.vue"),
                },
                {
                    path: "/registration-advance-payment/view/:id",
                    name: 'registrationAdvancePaymentView',
                    component: () => import ("./views/registration-manage/advance-payment/View.vue"),
                },
                {
                    path: "/registration-advance-payment/approve-list",
                    name: 'registrationAdvancePaymentApproveList',
                    component: () => import ("./views/registration-manage/advance-payment/ApproveList.vue"),
                },
                {
                    path: "/registration-advance-payment/approve/:id",
                    name: 'registrationAdvancePaymentApproveEdit',
                    component: () => import ("./views/registration-manage/advance-payment/ApproveEdit.vue"),
                },
                {
                    path: "/registration-invoice",
                    name: 'registrationinvoiceList',
                    component: () => import ("./views/registration-manage/invoice/List.vue"),
                },
                {
                    path: "/registration-invoice/crud/:id?",
                    name: 'registrationinvoiceCrud',
                    component: () => import ("./views/registration-manage/invoice/Crud.vue"),
                },
                {
                    path: "/registration-invoice/view/:id",
                    name: 'registrationinvoiceView',
                    component: () => import ("./views/registration-manage/invoice/View.vue"),
                },
                {
                    path: "/registration-invoice/approve-list",
                    name: 'registrationinvoiceApproveList',
                    component: () => import ("./views/registration-manage/invoice/ApproveList.vue"),
                },
                {
                    path: "/registration-invoice/approve/:id",
                    name: 'registrationinvoiceApproveEdit',
                    component: () => import ("./views/registration-manage/invoice/ApproveEdit.vue"),
                },
                {
                    path: "/report-registration-summary",
                    name: 'reportRegistrationSummary',
                    component: () => import ("./views/registration-manage/report-summary/ReportSummary.vue"),
                },
                {
                    path: "/report-registration-vehicle",
                    name: 'reportRegistrationVehicle',
                    component: () => import ("./views/registration-manage/report-vehicle/ReportVehicle.vue"),
                },
                {
                    path: "/report-vehicle-registration",
                    name: 'reportVehicleRegistration',
                    component: () => import ("./views/vehicle-manage/report/ReportRegistration.vue"),
                },
                {
                    path: "/log-data",
                    name: 'logDataList',
                    component: () => import ("./views/system/log-data/List.vue"),
                },
                {
                    path: "/setting-fee",
                    name: 'SettingFeeList',
                    component: () => import ("./views/transport-manage/setting-fee/List.vue"),
                },
                {
                    path: "/setting-debt-customer",
                    name: 'SettingDebtCustomerList',
                    component: () => import ("./views/transport-manage/setting-debt-customer/List.vue"),
                },
                {
                    path: "/setting-debt-partner",
                    name: 'SettingDebtPartnerList',
                    component: () => import ("./views/transport-manage/setting-debt-partner/List.vue"),
                },
                {
                    path: "/sector",
                    name: 'sectorList',
                    component: () => import ("./views/master-data/sector/List.vue"),
                },
                {
                    path: "/sector/crud/:id?",
                    name: 'sectorCrud',
                    component: () => import ("./views/master-data/sector/Crud.vue"),
                },
                {
                    path: "/setting-group-customer",
                    name: 'settingGroupCustomerList',
                    component: () => import ("./views/transport-manage/setting-group-customer/List.vue"),
                },
                {
                    path: "/setting-group-partner",
                    name: 'settingGroupPartnerList',
                    component: () => import ("./views/transport-manage/setting-group-partner/List.vue"),
                },
                {
                    path: "/product-unit",
                    name: 'productUnitList',
                    component: () => import ("./views/master-data/product-unit/List.vue"),
                },
                {
                    path: "/product-unit/crud/:id?",
                    name: 'productUnitCrud',
                    component: () => import ("./views/master-data/product-unit/Crud.vue"),
                },
                {
                    path: "/category-product",
                    name: 'categoryProductList',
                    component: () => import ("./views/master-data/category-product/List.vue"),
                },
                {
                    path: "/category-product/crud/:id?",
                    name: 'categoryProductCrud',
                    component: () => import ("./views/master-data/category-product/Crud.vue"),
                },
                {
                    path: "/category-xnk-type",
                    name: 'categoryXnkTypeList',
                    component: () => import ("./views/master-data/category-xnk-type/List.vue"),
                },
                {
                    path: "/order-estimate",
                    name: 'orderEstimateList',
                    component: () => import ("./views/order-manage/order-estimate/List.vue"),
                },
                {
                    path: "/order-estimate/view/:id?",
                    name: 'orderEstimateView',
                    component: () => import ("./views/order-manage/order-estimate/View.vue"),
                },
                {
                    path: "/order-estimate/approve-list",
                    name: 'orderEstimateApproveList',
                    component: () => import ("./views/order-manage/order-estimate/ApproveList.vue"),
                },
                {
                    path: "/order-estimate/approve/:id?",
                    name: 'orderEstimateApproveEdit',
                    component: () => import ("./views/order-manage/order-estimate/ApproveEdit.vue"),
                },
                {
                    path: "/order-advance-payment",
                    name: 'orderAdvancePaymentList',
                    component: () => import ("./views/order-manage/order-advance-payment/List.vue"),
                },
                {
                    path: "/order-advance-payment/crud/:id?",
                    name: 'orderAdvancePaymentCrud',
                    component: () => import ("./views/order-manage/order-advance-payment/Crud.vue"),
                },
                {
                    path: "/order-advance-payment/view/:id?",
                    name: 'orderAdvancePaymentView',
                    component: () => import ("./views/order-manage/order-advance-payment/View.vue"),
                },
                {
                    path: "/order-advance-payment/view-2/:id?",
                    name: 'orderAdvancePaymentView2',
                    component: () => import ("./views/order-manage/order-advance-payment/View-2.vue"),
                },
                {
                    path: "/order-advance-payment/approve-list",
                    name: 'order-advance-paymentApproveList',
                    component: () => import ("./views/order-manage/order-advance-payment/ApproveList.vue"),
                },
                {
                    path: "/order-advance-payment/approve/:id?",
                    name: 'order-advance-paymentApproveEdit',
                    component: () => import ("./views/order-manage/order-advance-payment/ApproveEdit.vue"),
                },
                {
                    path: "/order-invoice",
                    name: 'orderInvoiceList',
                    component: () => import ("./views/order-manage/order-invoice/List.vue"),
                },
                {
                    path: "/order-invoice/crud/:id?",
                    name: 'orderInvoiceCrud',
                    component: () => import ("./views/order-manage/order-invoice/Crud.vue"),
                },
                {
                    path: "/order-invoice/approve-list",
                    name: 'order-invoiceApproveList',
                    component: () => import ("./views/order-manage/order-invoice/ApproveList.vue"),
                },
                {
                    path: "/order-invoice/approve/:id?",
                    name: 'order-invoiceApproveEdit',
                    component: () => import ("./views/order-manage/order-invoice/ApproveEdit.vue"),
                },
                {
                    path: "/order-invoice/view/:id?",
                    name: 'orderInvoiceView',
                    component: () => import ("./views/order-manage/order-invoice/View.vue"),
                },
                {
                    path: "/distribute",
                    name: 'distributeList',
                    component: () => import ("./views/job-manage/distribute/List.vue"),
                },
                {
                    path: "/order-vehicle",
                    name: 'orderVehicleList',
                    component: () => import ("./views/job-manage/order-vehicle/Index.vue"),
                },
                {
                    path: "/setting-job-fee",
                    name: 'SettingJobFeeList',
                    component: () => import ("./views/job-manage/setting-fee/List.vue"),
                },
                {
                    path: "/job",
                    name: 'jobList',
                    component: () => import ("./views/job-manage/job/List.vue"),
                },
                {
                    path: "/setting-temp-fee",
                    name: 'settingTempFeeList',
                    component: () => import ("./views/settings/temp-fee/List.vue"),
                },
                {
                    path: "/payslip",
                    name: 'payslipList',
                    component: () => import ("./views/job-manage/payslip/List.vue"),
                },
                {
                    path: "/payslip/approve-list",
                    name: 'payslipApproveList',
                    component: () => import ("./views/job-manage/payslip/ApproveList.vue"),
                },
                {
                    path: "/payslip/approve/:id",
                    name: 'payslipApprove',
                    component: () => import ("./views/job-manage/payslip/ApproveEdit.vue"),
                },
                {
                    path: "/fixed-cost",
                    name: 'fixedCostList',
                    component: () => import ("./views/job-manage/fixed-cost/List.vue"),
                },
                {
                    path: "/report-driver-salary",
                    name: 'report-driver-salary-list',
                    component: () => import ("./views/report/report-driver-salary/List.vue"),
                },
                {
                    path: "/report-summary-fuel-driver",
                    name: 'report-summary-fuel-driver-list',
                    component: () => import ("./views/report/report-summary-fuel-driver/List.vue"),
                },
                {
                    path: "/report-summary-vehicle",
                    name: 'report-summary-vehicle-list',
                    component: () => import ("./views/report/report-summary-vehicle/List.vue"),
                },
                {
                    path: "/report-summary-goods",
                    name: 'report-summary-goods-list',
                    component: () => import ("./views/report/report-summary-goods/List.vue"),
                },
                {
                    path: "/report-summary-customer",
                    name: 'report-summary-customer-list',
                    component: () => import ("./views/report/report-summary-customer/List.vue"),
                },
                {
                    path: "/deposit-cont",
                    name: 'deposit-cont-list',
                    component: () => import ("./views/order-manage/deposit-cont/List.vue"),
                },
                {
                    path: "/deposit-repair",
                    name: 'deposit-repair-list',
                    component: () => import ("./views/order-manage/deposit-repair/List.vue"),
                },
                {
                    path: "/category-helper",
                    name: 'category-helper-list',
                    component: () => import ("./views/master-data/category-helper/List.vue"),
                },
                {
                    path: "/helper",
                    name: 'helper-list',
                    component: () => import ("./views/master-data/helper/List.vue"),
                },
                {
                    path: "/helper/crud/:id?",
                    name: 'helper-crud',
                    component: () => import ("./views/master-data/helper/Crud.vue"),
                },
                {
                    path: "/store-loan",
                    name: 'store-loan-list',
                    component: () => import ("./views/store-manage/store-loan/List.vue"),
                },
                {
                    path: "/store-loan/crud/:id?",
                    name: 'store-loan-crud',
                    component: () => import ("./views/store-manage/store-loan/Crud.vue"),
                },
                {
                    path: "/store-loan/approve-list",
                    name: 'store-loan-approve-list',
                    component: () => import ("./views/store-manage/store-loan/ApproveList.vue"),
                },
                {
                    path: "/store-loan/approve/:id",
                    name: 'store-loan-approve-crud',
                    component: () => import ("./views/store-manage/store-loan/ApproveEdit.vue"),
                },
                {
                    path: "/store-loan/view/:id",
                    name: 'store-loan-view',
                    component: () => import ("./views/store-manage/store-loan/View.vue"),
                },
                {
                    path: "/store-loan/view-attach/:id",
                    name: 'store-loan-view-attach',
                    component: () => import ("./views/store-manage/store-loan/ViewAttach.vue"),
                },
                {
                    path: "/store-return",
                    name: 'store-return-list',
                    component: () => import ("./views/store-manage/store-return/List.vue"),
                },
                {
                    path: "/store-return/crud/:id?",
                    name: 'store-return-crud',
                    component: () => import ("./views/store-manage/store-return/Crud.vue"),
                },
                {
                    path: "/store-return/approve-list",
                    name: 'store-return-approve-list',
                    component: () => import ("./views/store-manage/store-return/ApproveList.vue"),
                },
                {
                    path: "/store-return/approve/:id",
                    name: 'store-return-approve-crud',
                    component: () => import ("./views/store-manage/store-return/ApproveEdit.vue"),
                },
                {
                    path: "/store-return/view/:id",
                    name: 'store-return-view',
                    component: () => import ("./views/store-manage/store-return/View.vue"),
                },
                {
                    path: "/store-return/view-attach/:id",
                    name: 'store-return-view-attach',
                    component: () => import ("./views/store-manage/store-return/ViewAttach.vue"),
                },
                {
                    path: "/report-cont",
                    name: 'report-cont',
                    component: () => import ("./views/report/report-cont/List.vue"),
                },
                {
                    path: "/fee-heavy",
                    name: 'fee-heavy',
                    component: () => import ("./views/heavy-manager/fee/List.vue"),
                },
                {
                    path: "/category-fee-heavy",
                    name: 'category-fee-heavy',
                    component: () => import ("./views/heavy-manager/category-fee/List.vue"),
                },
                {
                    path: "/order-heavy",
                    name: 'order-heavy-list',
                    component: () => import ("./views/heavy-manager/order/List.vue"),
                },
                {
                    path: "/order-heavy/crud/:id?",
                    name: 'order-heavy-crud',
                    component: () => import ("./views/heavy-manager/order/Crud.vue"),
                },
                {
                    path: "/goods-heavy",
                    name: 'goodsheavyList',
                    component: () => import ("./views/heavy-manager/goods/List.vue"),
                },
                {
                    path: "/unit-heavy",
                    name: 'unit-heavy-list',
                    component: () => import ("./views/heavy-manager/unit/List.vue"),
                },
                {
                    path: "/job-vendor",
                    name: 'job-vendor-list',
                    component: () => import ("./views/job-manage/job-vendor/List.vue"),
                },
                {
                    path: "/settings",
                    name: 'settings-list',
                    component: () => import ("./views/master-data/settings/List.vue"),
                },
                {
                    path: "/file",
                    name: 'file-list',
                    component: () => import ("./views/master-data/file/List.vue"),
                },
                {
                    path: "/team",
                    name: 'teamList',
                    component: () => import ("./views/master-data/team/List.vue"),
                },
                {
                    path: "/team/crud/:id?",
                    name: 'team-crud',
                    component: () => import ("./views/master-data/team/Crud.vue"),
                },
                {
                    path: "/report-fee-on-behalf",
                    name: 'report-fee-on-behalf',
                    component: () => import ("./views/report/report-fee-on-behalf/List.vue"),
                },
                {
                    path: "/report-goods-on-behalf",
                    name: 'report-goods-on-behalf',
                    component: () => import ("./views/report/report-goods-on-behalf/List.vue"),
                },
                {
                    path: "/refund/view/:id",
                    name: 'refund-view',
                    component: () => import ("./views/debt-manager/refund/View.vue"),
                },
                {
                    path: "/project-heavy",
                    name: 'projectHeavyList',
                    component: () => import ("./views/heavy-manager/project/List.vue"),
                },
                {
                    path: "/project-heavy/crud/:id?",
                    name: 'project-heavy-crud',
                    component: () => import ("./views/heavy-manager/project/Crud.vue"),
                },
                {
                    path: "/project-period",
                    name: 'project-period-list',
                    component: () => import ("./views/heavy-manager/project-period/List.vue"),
                },
                {
                    path: "/order-heavy-estimate",
                    name: 'orderheavyEstimateList',
                    component: () => import ("./views/heavy-manager/order-estimate/List.vue"),
                },
                {
                    path: "/order-heavy-estimate/view/:id?",
                    name: 'orderheavyEstimateView',
                    component: () => import ("./views/heavy-manager/order-estimate/View.vue"),
                },
                {
                    path: "/order-heavy-estimate/approve-list",
                    name: 'orderheavyEstimateApproveList',
                    component: () => import ("./views/heavy-manager/order-estimate/ApproveList.vue"),
                },
                {
                    path: "/order-heavy-estimate/approve/:id?",
                    name: 'orderheavyEstimateApproveEdit',
                    component: () => import ("./views/heavy-manager/order-estimate/ApproveEdit.vue"),
                },
                {
                    path: "/order-heavy-advance-payment",
                    name: 'order-heavyAdvancePaymentList',
                    component: () => import ("./views/heavy-manager/order-advance-payment/List.vue"),
                },
                {
                    path: "/order-heavy-advance-payment/crud/:id?",
                    name: 'order-heavyAdvancePaymentCrud',
                    component: () => import ("./views/heavy-manager/order-advance-payment/Crud.vue"),
                },
                {
                    path: "/order-heavy-advance-payment/approve-list",
                    name: 'order-heavy-advance-paymentApproveList',
                    component: () => import ("./views/heavy-manager/order-advance-payment/ApproveList.vue"),
                },
                {
                    path: "/order-heavy-advance-payment/approve/:id?",
                    name: 'order-heavy-advance-paymentApproveEdit',
                    component: () => import ("./views/heavy-manager/order-advance-payment/ApproveEdit.vue"),
                },
                {
                    path: "/transport-mode",
                    name: 'transport-mode',
                    component: () => import ("./views/heavy-manager/transport-mode/List.vue"),
                },
                {
                    path: "/order-vehicle-heavy",
                    name: 'order-vehicle-heavy',
                    component: () => import ("./views/heavy-manager/order-vehicle/List.vue"),
                },
                {
                    path: "/order-vehicle-heavy/crud/:id?",
                    name: 'order-vehicle-heavy-crud',
                    component: () => import ("./views/heavy-manager/order-vehicle/Crud.vue"),
                },
                {
                    path: "/vehicle-outside",
                    name: 'vehicle-outside',
                    component: () => import ("./views/heavy-manager/vehicle-outside/List.vue"),
                },
                {
                    path: "/order-heavy-invoice",
                    name: 'order-heavy-invoice-list',
                    component: () => import ("./views/heavy-manager/order-invoice/List.vue"),
                },
                {
                    path: "/order-heavy-invoice/crud/:id?",
                    name: 'order-heavyinvoiceCrud',
                    component: () => import ("./views/heavy-manager/order-invoice/Crud.vue"),
                },
                {
                    path: "/order-heavy-invoice/view/:id?",
                    name: 'orderheavyinvoiceView',
                    component: () => import ("./views/heavy-manager/order-invoice/View.vue"),
                },
                {
                    path: "/order-heavy-invoice/approve-list",
                    name: 'order-heavy-invoiceApproveList',
                    component: () => import ("./views/heavy-manager/order-invoice/ApproveList.vue"),
                },
                {
                    path: "/order-heavy-invoice/approve/:id?",
                    name: 'order-heavy-invoiceApproveEdit',
                    component: () => import ("./views/heavy-manager/order-invoice/ApproveEdit.vue"),
                },
                {
                    path: "/project-estimate",
                    name: 'project-estimate-list',
                    component: () => import ("./views/heavy-manager/project-estimate/List.vue"),
                },
                {
                    path: "/project-estimate/view/:id?",
                    name: 'projectEstimateView',
                    component: () => import ("./views/heavy-manager/project-estimate/View.vue"),
                },
                {
                    path: "/project-estimate/approve-list",
                    name: 'projectEstimateApproveList',
                    component: () => import ("./views/heavy-manager/project-estimate/ApproveList.vue"),
                },
                {
                    path: "/project-estimate/approve/:id?",
                    name: 'projectEstimateApproveEdit',
                    component: () => import ("./views/heavy-manager/project-estimate/ApproveEdit.vue"),
                },
                {
                    path: "/project-advance-payment",
                    name: 'project-advance-payment-list',
                    component: () => import ("./views/heavy-manager/project-advance-payment/List.vue"),
                },
                {
                    path: "/project-advance-payment/crud/:id?",
                    name: 'project-advance-payment-crud',
                    component: () => import ("./views/heavy-manager/project-advance-payment/Crud.vue"),
                },
                {
                    path: "/project-advance-payment/view/:id?",
                    name: 'project-advance-payment-view',
                    component: () => import ("./views/heavy-manager/project-advance-payment/View.vue"),
                },
                {
                    path: "/project-advance-payment/approve-list",
                    name: 'project-advance-payment-approve-list',
                    component: () => import ("./views/heavy-manager/project-advance-payment/ApproveList.vue"),
                },
                {
                    path: "/project-advance-payment/approve/:id?",
                    name: 'project-advance-payment-approve',
                    component: () => import ("./views/heavy-manager/project-advance-payment/ApproveEdit.vue"),
                },
                {
                    path: "/project-invoice",
                    name: 'project-invoice-list',
                    component: () => import ("./views/heavy-manager/project-invoice/List.vue"),
                },
                {
                    path: "/project-invoice/crud/:id?",
                    name: 'project-invoice-crud',
                    component: () => import ("./views/heavy-manager/project-invoice/Crud.vue"),
                },
                {
                    path: "/project-invoice/view/:id?",
                    name: 'project-invoice-view',
                    component: () => import ("./views/heavy-manager/project-invoice/View.vue"),
                },
                {
                    path: "/project-invoice/approve-list",
                    name: 'project-invoice-approve-list',
                    component: () => import ("./views/heavy-manager/project-invoice/ApproveList.vue"),
                },
                {
                    path: "/project-invoice/approve/:id?",
                    name: 'project-invoice-approve',
                    component: () => import ("./views/heavy-manager/project-invoice/ApproveEdit.vue"),
                },
                {
                    path: "/accountant-invoice",
                    name: 'accountant-invoice-list',
                    component: () => import ("./views/accountant/invoice/List.vue"),
                },
                {
                    path: "/accountant-invoice/crud/:id?",
                    name: 'accountant-invoice-crud',
                    component: () => import ("./views/accountant/invoice/Crud.vue"),
                },
                {
                    path: "/accountant-invoice-customer",
                    name: 'accountant-invoice-customer-list',
                    component: () => import ("./views/accountant/invoice-customer/List.vue"),
                },
                {
                    path: "/accountant-invoice-customer/crud/:id?",
                    name: 'accountant-invoice-customer-crud',
                    component: () => import ("./views/accountant/invoice-customer/Crud.vue"),
                },
                {
                    path: "/project-budget",
                    name: 'project-budget-list',
                    component: () => import ("./views/heavy-manager/project-budget/List.vue"),
                },
                {
                    path: "/project-budget/view/:id?",
                    name: 'projectbudgetView',
                    component: () => import ("./views/heavy-manager/project-budget/View.vue"),
                },
                {
                    path: "/project-budget/approve-list",
                    name: 'projectbudgetApproveList',
                    component: () => import ("./views/heavy-manager/project-budget/ApproveList.vue"),
                },
                {
                    path: "/project-budget/approve/:id?",
                    name: 'projectbudgetApproveEdit',
                    component: () => import ("./views/heavy-manager/project-budget/ApproveEdit.vue"),
                },
                {
                    path: "/report-accountant-ppl-invoice",
                    name: 'accountant-invoice-ppl-customer',
                    component: () => import ("./views/accountant/invoice/List.vue"),
                },
                {
                    path: "/report-accountant-customer-invoice",
                    name: 'accountant-invoice-customer-ppl',
                    component: () => import ("./views/accountant/invoice-customer/List.vue"),
                },
                {
                    path: "/report-revenue",
                    name: 'report-revenue',
                    component: () => import ("./views/revenue/List.vue"),
                },
                {
                    path: "/report-debt-customer",
                    name: 'report-debt-customer',
                    component: () => import ("./views/debt-manager/debt-customer/List.vue"),
                },
                {
                    path: "/report-refund",
                    name: 'report-refund',
                    component: () => import ("./views/debt-manager/refund/List.vue"),
                },
                {
                    path: "/report-debt-vendor",
                    name: 'report-debt-vendor',
                    component: () => import ("./views/debt-manager/debt-vendor/List.vue"),
                },
                {
                    path: "/report-store-receipt",
                    name: 'report-store-receipt',
                    component: () => import ("./views/store-manage/store-receipt/List.vue"),
                },
                {
                    path: "/report-store-export",
                    name: 'report-store-export',
                    component: () => import ("./views/store-manage/store-export/List.vue"),
                },
                {
                    path: "/report-order-estimate",
                    name: 'report-order-estimate',
                    component: () => import ("./views/order-manage/order-estimate/List.vue"),
                },
                {
                    path: "/report-transport-log",
                    name: 'report-transport-log',
                    component: () => import ("./views/report/report-transport-log/List.vue"),
                },
                {
                    path: "/report-transport-ktkt",
                    name: 'report-transport-ktkt',
                    component: () => import ("./views/report/report-transport-ktkt/List.vue"),
                },
                {
                    path: "/report-revenue-customer",
                    name: 'report-revenue-customer',
                    component: () => import ("./views/report/report-revenue-customer/List.vue"),
                },
                {
                    path: "/report-revenue-customer-by-handle-fee",
                    name: 'report-revenue-customer-by-handle-fee',
                    component: () => import ("./views/report/report-revenue-customer-by-handle-fee/List.vue"),
                },
                {
                    path: "/report-transport-vendor",
                    name: 'report-transport-vendor',
                    component: () => import ("./views/report/report-transport-vendor/List.vue"),
                },
                {
                    path: "/report-handle-fee-1",
                    name: 'report-handle-fee-1',
                    component: () => import ("./views/report/report-handle-fee-1/List.vue"),
                },
                {
                    path: "/report-handle-fee-2",
                    name: 'report-handle-fee-2',
                    component: () => import ("./views/report/report-handle-fee-2/List.vue"),
                },
                {
                    path: "/report-repair-history",
                    name: 'report-repair-history',
                    component: () => import ("./views/repair-manage/report-repair-history/List.vue"),
                },
                {
                    path: "/report-fee-onbehalf-ktkt",
                    name: 'report-fee-onbehalf-ktkt',
                    component: () => import ("./views/report/report-fee-onbehalf-ktkt/List.vue"),
                },
                {
                    path: "/fuel",
                    name: 'fuel-list',
                    component: () => import ("./views/fuel-manager/fuel/List.vue"),
                },
                {
                    path: "/fuel/view/:id?",
                    name: 'fuel-view',
                    component: () => import ("./views/fuel-manager/fuel/View.vue"),
                },
                {
                    path: "/fuel-submited",
                    name: 'fuel-submited-list',
                    component: () => import ("./views/fuel-manager/fuel-submited/List.vue"),
                },
                {
                    path: "/fuel-submited/crud/:id?",
                    name: 'fuel-submited-crud',
                    component: () => import ("./views/fuel-manager/fuel-submited/Crud.vue"),
                },
                {
                    path: "/report-fuel",
                    name: 'report-fuel',
                    component: () => import ("./views/fuel-manager/fuel/List.vue"),
                },
                {
                    path: "/report-fuel-submited",
                    name: 'report-fuel-submited',
                    component: () => import ("./views/fuel-manager/fuel-submited/List.vue"),
                },
                {
                    path: "/estimate",
                    name: 'estimate-list',
                    component: () => import ("./views/estimate-manager/estimate/List.vue"),
                },
                {
                    path: "/estimate/crud/:id?",
                    name: 'estimate-crud',
                    component: () => import ("./views/estimate-manager/estimate/Crud.vue"),
                },
                {
                    path: "/estimate/view/:id?",
                    name: 'estimate-view',
                    component: () => import ("./views/estimate-manager/estimate/View.vue"),
                },
                {
                    path: "/estimate/approve-list",
                    name: 'estimate-approve-list',
                    component: () => import ("./views/estimate-manager/estimate/ApproveList.vue"),
                },
                {
                    path: "/estimate/approve/:id?",
                    name: 'estimate-approve',
                    component: () => import ("./views/estimate-manager/estimate/ApproveEdit.vue"),
                },
                {
                    path: "/advance-payment",
                    name: 'advance-payment-list',
                    component: () => import ("./views/estimate-manager/advance-payment/List.vue"),
                },
                {
                    path: "/advance-payment/crud/:id?",
                    name: 'advance-payment-crud',
                    component: () => import ("./views/estimate-manager/advance-payment/Crud.vue"),
                },
                {
                    path: "/advance-payment/view/:id?",
                    name: 'advance-payment-view',
                    component: () => import ("./views/estimate-manager/advance-payment/View.vue"),
                },
                {
                    path: "/advance-payment/approve-list",
                    name: 'advance-payment-approve-list',
                    component: () => import ("./views/estimate-manager/advance-payment/ApproveList.vue"),
                },
                {
                    path: "/advance-payment/approve/:id?",
                    name: 'advance-payment-approve',
                    component: () => import ("./views/estimate-manager/advance-payment/ApproveEdit.vue"),
                },
                {
                    path: "/invoice",
                    name: 'invoice-list',
                    component: () => import ("./views/estimate-manager/invoice/List.vue"),
                },
                {
                    path: "/invoice/crud/:id?",
                    name: 'invoice-crud',
                    component: () => import ("./views/estimate-manager/invoice/Crud.vue"),
                },
                {
                    path: "/invoice/view/:id?",
                    name: 'invoice-view',
                    component: () => import ("./views/estimate-manager/invoice/View.vue"),
                },
                {
                    path: "/invoice/approve-list",
                    name: 'invoice-approve-list',
                    component: () => import ("./views/estimate-manager/invoice/ApproveList.vue"),
                },
                {
                    path: "/invoice/approve/:id?",
                    name: 'invoice-approve',
                    component: () => import ("./views/estimate-manager/invoice/ApproveEdit.vue"),
                },
            ]
        },
        {
            path: "/login",
            component: EmptyLayout,
            meta: {
                requiresAuth: false
            },
            children: [
                {
                    path: "",
                    component: Login,
                    meta: {
                        requiresAuth: false
                    },
                },
                {
                    path: "/access-denied",
                    name: 'AccessDenied',
                    component: () => import ("./views/system/auth/AccessDenied.vue"),
                },
            ]
        },
    ],
});

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (TokenService.getToken() != '' && TokenService.getToken() != null) {
            next()
            return
        }
        else {
            next('/login')
        }
    } else {
        next()
    }
})

export default router 