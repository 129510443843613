const TOKEN_KEY = 'ppl_access_token';
const REFRESH_TOKEN_KEY = 'ppl_refresh_access_token';
const USER_KEY = 'fullName';
const USERNAME_KEY = 'username';
const PASSWORD_KEY = 'password';
const ROLE_KEY = 'role';

const TokenService = {
    getRefreshToken() {
        return localStorage.getItem(REFRESH_TOKEN_KEY)
    },

    saveRefreshToken(refreshToken) {
        localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken)
    },

    removeRefreshToken() {
        localStorage.removeItem(REFRESH_TOKEN_KEY)
    },

    getToken() {
        return localStorage.getItem(TOKEN_KEY)
    },

    saveToken(accessToken) {
        localStorage.setItem(TOKEN_KEY, accessToken)
    },

    removeToken() {
        localStorage.removeItem(TOKEN_KEY)
    },

    saveUserName(user){
        localStorage.setItem(USERNAME_KEY, user)
    },

    getUserName() {
        return localStorage.getItem(USERNAME_KEY)
    },

    saveUser(user){
        localStorage.setItem(USER_KEY, user)
    },

    getUser() {
        return localStorage.getItem(USER_KEY)
    },

    saveRole(role){
        localStorage.setItem(ROLE_KEY, role)
    },

    getRole() {
        return localStorage.getItem(ROLE_KEY)
    },
}

const LoginInfoService = {
    getUser() {
        return localStorage.getItem(USERNAME_KEY)
    },

    saveUser(username) {
        localStorage.setItem(USERNAME_KEY, username)
    },

    removeUser() {
        localStorage.removeItem(USERNAME_KEY)
    },

    getPassword() {
        return localStorage.getItem(PASSWORD_KEY)
    },

    savePassword(password) {
        localStorage.setItem(PASSWORD_KEY, password)
    },

    removePassword() {
        localStorage.removeItem(PASSWORD_KEY)
    },
}

export { TokenService, LoginInfoService }