const loading = state => state.loading;
const loggedIn = state => state.loggedIn;
const user = state => state.user;
const token = state => state.token;

export default {
    loading,
    loggedIn,
    user,
    token
};