import * as types from "./constants";

const setLoading = ({ commit }, data) => {
    commit(types.SET_LOADING, data);
}

const setAuth = ({ commit }, token, user) => {
    commit('auth_success', token, user);
}

const setLoggedIn = ({ commit }, loggedIn) => {
    commit('logged_in', loggedIn);
}

const setLogout = ({ commit }) => {
    commit('logout');
}

export default {
    setLoading,
    setAuth,
    setLogout,
    setLoggedIn
};