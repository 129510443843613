import axios from 'axios'
import { TokenService } from './storageService'
import { APP_CONFIG } from '../utils/constants'
import router from "./../router";
import Vue from 'vue'

const getAuthToken = () => TokenService.getToken();

const httpClient = axios.create({
    baseURL: APP_CONFIG.apiUrl,
    headers: {
        'Accept': 'application/json',
        'Content-Type': "application/json",
    }
});

export const authInterceptor = (config) => {
    config.headers['Authorization'] = 'Bearer ' + getAuthToken();
    return config;
}

httpClient.interceptors.request.use(authInterceptor);

httpClient.interceptors.response.use(
    function(response) {
        return response.data;
    },
    async function(error) {
        console.log('api error', error)
        if (error.response.status === 401) {
            router.push('/login');
        }
        else if(error.response.status === 405){
            router.push('/access-denied');
        }
        else{
            console.error(error.response.status, error.message);
            Vue.notify({
                group: 'foo',
                type: 'error',
                title: 'Server Error',
                text: error.message
            });
        }
        return Promise.reject(error);
    }
);

export default httpClient;
